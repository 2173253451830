import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box, Checkbox, FormControlLabel, IconButton, Tooltip,
  Typography,
} from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef as MrtColumnDef,
  type MRT_ColumnFiltersState as MrtColumnFiltersState,
  type MRT_PaginationState as MrtPaginationState,
  type MRT_Row as MrtRow,
  type MRT_SortingState as MrtSortingState,
} from 'material-react-table';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select, { components, GroupBase, MultiValueProps } from 'react-select'
import { USER_TYPES } from '../../../constants'
import '../../../styles/common.css';

interface OptionType {
  value: string;
  label: string;
}

interface MrtDataGridProps<T extends Record<string, any>> {
  columns: MrtColumnDef<T>[];
  data: T[];
  totalRows: number;
  isLoading?: boolean;
  isError?: boolean;
  onRefetch?: () => void;
  onClearFilters?: () => void;
  onColumnFiltersChange: React.Dispatch<React.SetStateAction<MrtColumnFiltersState>>;
  setPagination: React.Dispatch<React.SetStateAction<MrtPaginationState>>;
  pagination: MrtPaginationState;
  setSorting: React.Dispatch<React.SetStateAction<MrtSortingState>>;
  sorting: MrtSortingState;
  initialState?: {
    showColumnFilters?: boolean;
    density?: 'compact' | 'comfortable' | 'spacious';
  };
  manualFiltering?: boolean;
  manualSorting?: boolean;
  showDeletedCheckbox?: boolean;
  showResolvedCheckbox?: boolean;
  showDeleted?: boolean;
  showResolved?: boolean;
  setShowDeleted?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowResolved?: React.Dispatch<React.SetStateAction<boolean>>;
  customRowStyle?: (row: MrtRow<T>) => Record<string, any>;
  onRowDoubleClick?: (row: MrtRow<T>) => void
  topToolbarContent?: ReactJSXElement
  showUserTypeBox?: boolean;
  showUserType?: OptionType[];
  setOnUserTypeChange?: React.Dispatch<React.SetStateAction<OptionType[]>>;
}

function MrtDataGrid<T extends Record<string, any>>({
  columns,
  data,
  totalRows,
  isLoading,
  isError,
  onRefetch,
  onClearFilters,
  onColumnFiltersChange,
  setPagination,
  pagination,
  setSorting,
  sorting,
  initialState,
  manualFiltering = true,
  manualSorting = true,
  showDeletedCheckbox = false,
  showResolvedCheckbox = false,
  showDeleted,
  showResolved,
  setShowDeleted,
  setShowResolved,
  customRowStyle,
  onRowDoubleClick,
  topToolbarContent,
  showUserType,
  showUserTypeBox = false,
  setOnUserTypeChange,
}: MrtDataGridProps<T>) {
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>(USER_TYPES)
  const selectRef = useRef<HTMLDivElement | null>(null)
  const width = Math.max(100, selectedOptions.length * 100)

  const handleRowDoubleClick = (row: MrtRow<T>) => {
    if (onRowDoubleClick) {
      onRowDoubleClick(row)
    } else {
      navigate(`/users/${row.id}`);
    }
  }

  const onInputChange = (selected: any) => {
    setSelectedOptions(selected);
  }

  useEffect(() => {
    setSelectedOptions(showUserType || selectedOptions)
  }, [showUserType, selectedOptions])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
    })

    const currentRef = selectRef.current

    if (currentRef) {
      resizeObserver.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef)
      }
    };
  }, [])

  useEffect(() => {
    if (setOnUserTypeChange) {
      setSelectedOptions(selectedOptions)
      setOnUserTypeChange(selectedOptions)
    }
  }, [selectedOptions, setOnUserTypeChange])

  const table = useMaterialReactTable({
    columns,
    data,
    rowCount: totalRows,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: true,
      density: 'compact',
      ...initialState,
    },
    manualFiltering,
    manualSorting,
    manualPagination: true,
    enableFacetedValues: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    enableMultiSort: false,
    enableStickyHeader: true,
    enableRowNumbers: true,
    muiTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => handleRowDoubleClick(row),
      sx: {
        cursor: 'pointer',
        ...(customRowStyle ? customRowStyle(row) : {}),
      },
    }),
    state: {
      isLoading,
      sorting,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [25, 50, 100],
      shape: 'rounded',
      variant: 'outlined',
    },
    columnFilterDisplayMode: 'subheader',
    onColumnFiltersChange,
    onPaginationChange: setPagination,
    onSortingChange: (updaterOrValue) => {
      const newSorting = typeof updaterOrValue === 'function'
        ? updaterOrValue(sorting)
        : updaterOrValue;
      setSorting(newSorting.slice(0, 1));
    },
    muiTablePaperProps: {
      sx: {
        display: 'flex', flexDirection: 'column',
      },
    },
    renderTopToolbarCustomActions: () => (
      <Box>
        {topToolbarContent}
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={onRefetch}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Clear Filters">
          <IconButton onClick={onClearFilters}>
            <FilterAltOffIcon />
          </IconButton>
        </Tooltip>
        {showDeletedCheckbox && (
          <FormControlLabel
            control={(
              <Checkbox
                color="error"
                checked={showDeleted}
                onChange={(e) => setShowDeleted?.(e.target.checked)}
              />
            )}
            label="Include Deleted"
          />
        )}
        {showUserTypeBox && (
          <>
            <FormControlLabel
              control={(
                <div>
                  User Types:
                </div>
              )}
              label=""
              sx={{ marginLeft: 0 }}
            />
            <FormControlLabel
              control={(
                <div ref={selectRef} style={{ minWidth: `${width}px` }}>
                  <Select
                    closeMenuOnSelect={false} // if select all in list, make this value dynamic based on that
                    isMulti
                    value={selectedOptions}
                    onChange={onInputChange}
                    components={{ Option, MultiValue }}
                    placeholder="Select type .."
                    hideSelectedOptions={false}
                    options={USER_TYPES}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                      control: (base: any) => ({
                        ...base,
                        minWidth: '100%',
                      }),
                    }}
                  />
                </div>
              )}
              label=""
              sx={{ marginLeft: -1 }}
            />
          </>
        )}
        {showResolvedCheckbox && (
          <FormControlLabel
            control={(
              <Checkbox
                checked={showResolved}
                onChange={(e) => setShowResolved?.(e.target.checked)}
              />
            )}
            label="Include Resolved"
          />
        )}
      </Box>
    ),
    renderBottomToolbarCustomActions: () => (
      <Typography variant="caption" color="red" sx={{ p: 2 }}>
        *Double-click a row to navigate to the details.
      </Typography>
    ),
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        maxWidth: 'fit-content',
      }}
    >
      <MaterialReactTable table={table} />
    </Box>
  );
}

function Option({ isSelected, label, ...props }: any) {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={isSelected}
          aria-label={label}
          onChange={() => null}
        />
        <label htmlFor={label}>{label}</label>
      </components.Option>
    </div>
  );
}

function MultiValue(props: MultiValueProps<OptionType, true, GroupBase<OptionType>>) {
  const classNameMap: { [key: string]: string } = {
    Normal: 'multi-value-normal',
    Delete: 'multi-value-deleted',
    Suspend: 'multi-value-suspended',
  }
  const className = classNameMap[props?.data.label] || ''
  return (
    <div style={{ marginRight: '3px' }}>
      <components.MultiValue {...props} className={className}>
        <span style={{ paddingLeft: '2px', paddingRight: '2px' }}>{props?.data.label}</span>
      </components.MultiValue>
    </div>
  );
}

export default MrtDataGrid;
