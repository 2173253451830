import React, { useMemo, useState } from 'react'
import {
  type MRT_ColumnDef as MrtColumnDef,
} from 'material-react-table';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import MrtDataGrid from '../../common/MrtDataGrid'
import { DateCell } from '../../../app/Timezone';
import CustomDateFilter from '../../common/date/CustomDateFilter';
import { useTableSearchParams } from '../../hooks/useTableSearchParams';

type Reports = {
  reporter: Report[],
  reported: Report[],
}

type Report = {
  id: string
  reporterQuickbloxUserId: number
  reportedQuickbloxUserId: number
  category: string
  details: string
  resolvedAt: string | null
  createdAt: string
  updatedAt: string | null
  deletedAt: string | null
}

export function UserReportsCard({ reports }: { reports: Reports }) {
  const [showReporterReports, setShowReporterReports] = useState(true);
  const [showReportedReports, setShowReportedReports] = useState(true);

  const displayData = useMemo(() => {
    let combinedData: Report[] = [];

    if (showReporterReports) {
      combinedData = [...combinedData, ...reports.reporter];
    }

    if (showReportedReports) {
      combinedData = [...combinedData, ...reports.reported];
    }

    if (showReporterReports && showReportedReports) {
      combinedData = Array.from(new Map(combinedData.map((item) => [item.id, item])).values());
    }

    return combinedData;
  }, [showReporterReports, showReportedReports, reports]);

  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    dateFilter,
    setDateFilter,
    clearFilters,
  } = useTableSearchParams();

  const { reporter } = reports

  const columns: MrtColumnDef<Report>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 100,
    },
    {
      accessorKey: 'reporterQuickbloxUserId',
      header: 'Reporter ID',
      size: 150,
    },
    {
      accessorKey: 'reportedQuickbloxUserId',
      header: 'Reported ID',
      size: 150,
    },
    {
      accessorKey: 'category',
      header: 'Category',
      size: 200,
    },
    {
      accessorKey: 'details',
      header: 'Details',
      size: 200,
    },
    {
      accessorKey: 'resolvedAt',
      header: 'Resolved At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'resolvedAt', filter: dateFilter, setFilter: setDateFilter }),
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'createdAt', filter: dateFilter, setFilter: setDateFilter }),
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'updatedAt', filter: dateFilter, setFilter: setDateFilter }),
    },
    {
      accessorKey: 'deletedAt',
      header: 'Deleted At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'deletedAt', filter: dateFilter, setFilter: setDateFilter }),
    },
  ]

  const customToolbarContent = (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <FormControlLabel
        control={(
          <Checkbox
            checked={showReporterReports}
            onChange={(e) => setShowReporterReports(e.target.checked)}
          />
        )}
        label={`Show Reports by User [${reporter.length}]`}
      />
      <FormControlLabel
        control={(
          <Checkbox
            checked={showReportedReports}
            onChange={(e) => setShowReportedReports(e.target.checked)}
          />
        )}
        label={`Show Reports to User [${reports.reported.length}]`}
      />
    </Box>
  );

  return (
    <MrtDataGrid
      columns={columns}
      data={displayData}
      totalRows={reporter.length}
      manualFiltering={false}
      manualSorting={false}
      onClearFilters={clearFilters}
      onColumnFiltersChange={setColumnFilters}
      pagination={pagination}
      setPagination={setPagination}
      sorting={sorting}
      setSorting={setSorting}
      topToolbarContent={customToolbarContent}
    />
  )
}
