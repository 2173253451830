import React from 'react'
import {
  Avatar, Box, Button, Card, CardContent, makeStyles, MenuItem, Select, Stack, styled, Table, TableBody, TableCell, TableContainer, TableRow, Typography,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import BlockIcon from '@mui/icons-material/Block';
import WidgetTagsAuth, { expectedWidgetDeleteUserTag, expectedWidgetUpdateUserTag } from '../../../common/WidgetTagsAuth'
import {
  useDeleteUser, useBanUser, useSwitchOfficialStatus, useSwitchStaffStatus, useChangeUserRegion,
  useSwitchAmbassadorStatus,
} from '../../../../api/query/users/users'
import { COUNTRIES, DEFAULT_COUNTRY } from '../../../../constants';
import { User } from '../types';
import { useConfirmationModal } from '../../hooks/useConfirmationModal';
import ConfirmationModal from '../../common/modals/ConfirmationModal';
import { DateText } from '../../../app/Timezone';

type UserDetailsCardProps = {
  user: User
}

type DetailRowProps = {
  label: string;
  value: React.ReactNode;
}

const ResponsiveButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    padding: '4px 8px',
    '& .MuiButton-startIcon': {
      marginRight: 4,
    },
    '& img': {
      height: 14,
    },
  },
}));

const ResponsiveSelect = styled(Select)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    minWidth: '100px',
  },
}));

function DetailRow({ label, value }: DetailRowProps) {
  return (
    <TableRow>
      <TableCell sx={{ fontWeight: 'bold' }}>{label}</TableCell>
      <TableCell sx={{
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
      }}
      >
        {value}
      </TableCell>
    </TableRow>
  );
}

export function UserDetailsCard({ user }: UserDetailsCardProps) {
  user.isDeleted = !!user.deletedAt;
  return (
    <>
      <Stack direction="row">
        <Avatar
          src={user.customData?.avatarUrl}
          sx={{
            width: { xs: 60, sm: 80, md: 100 },
            height: { xs: 60, sm: 80, md: 100 },
            m: { xs: 1, sm: 2 },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            }}
          >
            {user.userName ? user.userName.charAt(0) : '?'}
          </Typography>
        </Avatar>

        <Stack direction="column" justifyContent="center">
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
              m: 0,
            }}
          >
            {user.fullName}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: 'text.secondary',
              fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem' },
            }}
          >
            {`@${user.userName}`}
          </Typography>
        </Stack>
      </Stack>
      <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateUserTag || expectedWidgetDeleteUserTag}>
        <UserControlButtons user={user} />
      </WidgetTagsAuth>
      <Card>
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                <DetailRow label="Quickblox User ID" value={user.quickbloxUserId} />
                <DetailRow label="Number of Dialogs" value={user.dialogsCount} />
                <DetailRow label="Phone" value={user.phone} />
                <DetailRow label="Avatar Url" value={user.customData?.avatarUrl} />
                <DetailRow label="Bio Audio Path" value={user.bioAudioPath} />
                <DetailRow label="Created At" value={<DateText date={user.createdAt} />} />
                <DetailRow label="Updated At" value={<DateText date={user.updatedAt} />} />
                <DetailRow label="Deleted At" value={<DateText date={user.deletedAt || ''} />} />
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  )
}

function UserControlButtons({ user }: { user: User }) {
  const {
    modalState,
    handleOpen,
    handleClose,
    handleConfirm,
  } = useConfirmationModal();

  const deleteUser = useDeleteUser();
  const banUser = useBanUser();
  const switchOfficialStatus = useSwitchOfficialStatus();
  const switchStaffStatus = useSwitchStaffStatus();
  const switchAmbassadorStatus = useSwitchAmbassadorStatus();
  const changeUserRegion = useChangeUserRegion();

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2 }}
      >
        <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateUserTag}>
          <ResponsiveSelect
            label="Region"
            value={user.region || DEFAULT_COUNTRY.code}
            onChange={(e) => handleOpen({
              title: 'Are you sure you want to change the region for this user?',
              confirmLabel: 'Change',
              onConfirm: () => changeUserRegion.mutate({ userId: user.id, region: e.target.value as string }),
            })}
          >
            {COUNTRIES.map((country) => (
              <MenuItem
                key={country.code}
                value={country.code}
              >
                {country.label}
              </MenuItem>
            ))}
          </ResponsiveSelect>
          <ResponsiveButton
            variant={user.isOfficial ? 'contained' : 'outlined'}
            color="info"
            startIcon={(
              <Box
                component="img"
                sx={{ height: 18 }}
                src={`${process.env.PUBLIC_URL}/officialBadge.png`}
              />
            )}
            onClick={() => handleOpen({
              title: 'Are you sure you want to change the official status for this user?',
              confirmLabel: user.isOfficial ? 'Remove' : 'Grant',
              onConfirm: () => switchOfficialStatus.mutate({ userId: user.id, isOfficial: !user.isOfficial }),
            })}
          >
            Official
          </ResponsiveButton>
          <ResponsiveButton
            variant={user.isStaff ? 'contained' : 'outlined'}
            color="info"
            startIcon={(
              <Box
                component="img"
                sx={{ height: 18 }}
                src={`${process.env.PUBLIC_URL}/staffBadge.png`}
              />
            )}
            onClick={() => handleOpen({
              title: 'Are you sure you want to change the staff status for this user?',
              confirmLabel: user.isStaff ? 'Remove' : 'Grant',
              onConfirm: () => switchStaffStatus.mutate({ userId: user.id, isStaff: !user.isStaff }),
            })}
          >
            Staff
          </ResponsiveButton>
          <ResponsiveButton
            variant={user.isAmbassador ? 'contained' : 'outlined'}
            color="info"
            startIcon={(
              <Box
                component="img"
                sx={{ height: 18 }}
                src={`${process.env.PUBLIC_URL}/ambassadorBadge.png`}
              />
            )}
            onClick={() => handleOpen({
              title: 'Are you sure you want to change the staff status for this user?',
              confirmLabel: user.isAmbassador ? 'Remove' : 'Grant',
              onConfirm: () => switchAmbassadorStatus.mutate({ userId: user.id, isAmbassador: !user.isAmbassador }),
            })}
          >
            Ambassador
          </ResponsiveButton>
          <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteUserTag}>
            <ResponsiveButton
              variant={user.isDeleted ? 'contained' : 'outlined'}
              color="error"
              startIcon={<Delete />}
              onClick={() => handleOpen({
                title: 'Are you sure you want to delete this user?',
                confirmLabel: user.isDeleted ? 'Restore' : 'Delete',
                onConfirm: () => deleteUser.mutate(user.id),
              })}
            >
              {user.isDeleted ? 'Deleted' : 'Delete'}
            </ResponsiveButton>
          </WidgetTagsAuth>
          <ResponsiveButton
            variant={user.isDeleted ? 'contained' : 'outlined'}
            color="error"
            startIcon={<Delete />}
            onClick={() => handleOpen({
              title: 'Are you sure you want to ban this user?',
              confirmLabel: user.isDeleted ? 'Unban' : 'Ban',
              onConfirm: () => banUser.mutate(user.id),
            })}
          >
            {user.isDeleted ? 'Banned' : 'Ban'}
          </ResponsiveButton>
          <ResponsiveButton
            variant={user.disabled ? 'contained' : 'outlined'}
            color="error"
            startIcon={<BlockIcon />}
            onClick={() => handleOpen({
              title: 'Are you sure you want to suspend this user?',
              confirmLabel: user.disabled ? 'Unsuspend' : 'Suspend',
              onConfirm: () => banUser.mutate(user.id),
            })}
          >
            {user.isDeleted ? 'Suspended' : 'Suspend'}
          </ResponsiveButton>
        </WidgetTagsAuth>
      </Stack>
      <ConfirmationModal
        {...modalState}
        onConfirm={handleConfirm}
        onCancel={handleClose}
      />
    </>
  )
}
